<script lang="ts" setup>
interface Props {
    size?: number;
}

const props = withDefaults(defineProps<Props>(), {
    size: 1,
});
</script>

<template>
    <svg
        :width="21 * props.size"
        :height="21 * props.size"
        viewBox="0 0 41 41"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M15.3522 0C19.3747 0 23.2324 1.59791 26.0767 4.44221C28.921 7.28651 30.5189 11.1442 30.5189 15.1667C30.5189 18.9233 29.1422 22.3767 26.8789 25.0367L27.5089 25.6667H29.3522L41.0189 37.3333L37.5189 40.8333L25.8522 29.1667V27.3233L25.2222 26.6933C22.4702 29.0425 18.9705 30.3331 15.3522 30.3333C11.3298 30.3333 7.47206 28.7354 4.62776 25.8911C1.78346 23.0468 0.185547 19.1891 0.185547 15.1667C0.185547 11.1442 1.78346 7.28651 4.62776 4.44221C7.47206 1.59791 11.3298 0 15.3522 0ZM15.3522 4.66667C9.51888 4.66667 4.85221 9.33333 4.85221 15.1667C4.85221 21 9.51888 25.6667 15.3522 25.6667C21.1855 25.6667 25.8522 21 25.8522 15.1667C25.8522 9.33333 21.1855 4.66667 15.3522 4.66667Z"
            fill="currentColor"
        />
    </svg>
</template>

<style scoped></style>
