<script lang="ts" setup>
interface Props {
    size?: number;
}

const props = withDefaults(defineProps<Props>(), {
    size: 1,
});
</script>

<template>
    <svg
        :width="21 * props.size"
        :height="21 * props.size"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_564_10598)">
            <path
                d="M2.75141 4.37875H2.60141V4.52875V19.2395C2.60141 20.8863 3.94386 22.2287 5.59061 22.2287H19.4122C21.059 22.2287 22.4014 20.8863 22.4014 19.2395V4.52875V4.37875H22.2514H2.75141ZM19.4122 23.4887H5.59061C3.24765 23.4887 1.34141 21.5825 1.34141 19.2395V3.74875C1.34141 3.40259 1.62525 3.11875 1.97141 3.11875H23.0314C23.3776 3.11875 23.6614 3.40259 23.6614 3.74875V19.2395C23.6614 21.5825 21.7552 23.4887 19.4122 23.4887Z"
                fill="currentColor"
                stroke="white"
                stroke-width="0.3"
            />
            <path
                d="M17.0303 11.2059C17.0303 13.6761 15.0007 15.6891 12.5003 15.6891C9.99996 15.6891 7.97031 13.6761 7.97031 11.2059V8.81906C7.97031 8.47291 8.25416 8.18906 8.60031 8.18906C8.94647 8.18906 9.23031 8.47291 9.23031 8.81906V11.2059C9.23031 12.9834 10.6997 14.4291 12.5003 14.4291C14.301 14.4291 15.7703 12.9834 15.7703 11.2059V8.81906C15.7703 8.47291 16.0542 8.18906 16.4003 8.18906C16.7465 8.18906 17.0303 8.47291 17.0303 8.81906V11.2059Z"
                fill="currentColor"
                stroke="white"
                stroke-width="0.3"
            />
        </g>
    </svg>
</template>

<style scoped></style>
