<script lang="ts" setup>
const { headerMenu } = useContextStore();
</script>

<template>
    <nav class="flex gap-5">
        <template v-for="link in headerMenu" :key="link.url.toString()">
            <NavigationBarMenuLink :link="link" />
        </template>
    </nav>
</template>

<style scoped></style>
