import type { MenuEntry } from '../models/Menu';
import type { ResourceCollection } from '../types/responses';
import ApiService from './base/ApiService';

export default class MenuService extends ApiService {
    async get(): Promise<ResourceCollection<MenuEntry>> {
        const endpoint = '/api/menu';
        const response =
            await this.fetch<ResourceCollection<MenuEntry>>(endpoint);

        return response;
    }
}
