<script lang="ts" setup>
import { type RouteLocationRaw } from 'vue-router';
import Caption from '../Text/Caption.vue';
import Body from '../Text/Body.vue';

interface Props {
    to: string | RouteLocationRaw;
    rounded?: boolean;
    size?: 'sm' | 'md';
    variant?:
        | 'primary'
        | 'primary-border'
        | 'secondary'
        | 'secondary-border'
        | 'primary-inverse'
        | 'secondary-inverse'
        | 'primary-inverse-border'
        | 'secondary-inverse-border';
    target?: string;
}

const props = withDefaults(defineProps<Props>(), {
    rounded: false,
    size: 'md',
    variant: 'primary',
    target: undefined,
});

const contentComponent = computed(() => {
    return props.size === 'sm' ? Caption : Body;
});

const isExternal = computed(() => {
    return typeof props.to === 'string' && props.to.startsWith('http');
});

const linkClasses = computed(() => {
    return {
        'rounded-lg': !props.rounded && props.size === 'sm',
        'rounded-xl': !props.rounded && props.size === 'md',
        'rounded-full': props.rounded,

        'px-2 py-2': props.size === 'sm',
        'px-5 py-2.5': props.size === 'md',

        'text-white border-transparent bg-uk-orange hover:text-uk-orange hover:bg-white':
            props.variant === 'primary',

        'text-white border-uk-orange bg-uk-orange hover:text-uk-orange hover:bg-white':
            props.variant === 'primary-border',

        'text-uk-orange border-transparent bg-white hover:text-white hover:bg-uk-orange':
            props.variant === 'primary-inverse',

        'text-uk-orange border-uk-orange bg-white hover:text-white hover:bg-uk-orange':
            props.variant === 'primary-inverse-border',

        'text-white border-white bg-uk-blue hover:text-uk-blue hover:bg-white':
            props.variant === 'secondary',

        'text-white border-uk-blue bg-uk-blue hover:text-uk-blue hover:bg-white':
            props.variant === 'secondary-border',

        'text-uk-blue border-transparent bg-white hover:text-white hover:bg-uk-blue':
            props.variant === 'secondary-inverse',

        'text-uk-blue border-uk-blue bg-white hover:text-white hover:bg-uk-blue':
            props.variant === 'secondary-inverse-border',
    };
});
</script>

<template>
    <NuxtLink
        :to="props.to"
        :external="isExternal"
        :class="linkClasses"
        :target="props.target"
        class="border box-border transition-colors flex items-center justify-center no-underline"
    >
        <component :is="contentComponent">
            <slot />
        </component>
    </NuxtLink>
</template>

<style scoped></style>
