<script lang="ts" setup>
import type { SearchResult } from '~/layers/api/models/Search';
import IconSearch from '~/layers/uikit/components/Icon/Search.vue';

const rb = useRouteBuilder();
const { searchService } = useApi();
const { value: term } = useField<string | undefined>('search');

const searchIconComponent = h(IconSearch, { size: 0.8 });

const options = ref<SearchResult[]>([]);
const pending = ref(false);

async function onOptionSelect(option: SearchResult) {
    await navigateTo(rb.toSearchItem(option));
}

async function search() {
    if (term.value === '' || term.value === undefined) {
        return;
    }

    pending.value = true;

    try {
        const response = await searchService.search(term.value);

        options.value = response;
    } catch (e) {
        //
    }

    pending.value = false;
}

function submit() {
    if (term.value === '' || term.value === undefined) {
        return;
    }

    const endpoint = rb.search(term.value);

    navigateTo(endpoint);
}

watchDebounced(term, search, { debounce: 500 });
</script>

<template>
    <FormProvider class="relative" @submit="submit">
        <FormFieldAutocomplete
            v-model="term"
            :icon="searchIconComponent"
            :clearable="true"
            :options="options"
            :pending="pending"
            name="search"
            placeholder="поиск"
            class="w-full"
            @action="submit"
            @option-select="onOptionSelect"
        >
            <template #option="{ option }">
                <TextCaption
                    class="cursor-pointer hover:text-uk-orange transition-colors"
                >
                    {{ formatSearchResultType(option.type) }} -
                    {{ option.name }}
                </TextCaption>
            </template>
        </FormFieldAutocomplete>
    </FormProvider>
</template>

<style scoped></style>
