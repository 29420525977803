<script lang="ts" setup>
interface Props {
    bold?: boolean;
}

const props = defineProps<Props>();
</script>

<template>
    <span :class="{ 'font-bold': props.bold }" class="text-xs xl:text-sm">
        <slot />
    </span>
</template>

<style scoped></style>
