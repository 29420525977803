<script lang="ts" setup>
const { headerMenu } = useContextStore();
</script>

<template>
    <AccordionContainer class="flex flex-col gap-y-2">
        <template v-for="(link, idx) in headerMenu">
            <AccordionItem
                v-if="link.children.length > 0"
                :key="idx"
                class="justify-between"
            >
                <template #header>
                    <LinkText :to="link.url" size="lg">
                        {{ link.text }}
                    </LinkText>
                </template>
                <template #default>
                    <ul class="pl-2 py-1 flex flex-col gap-y-1">
                        <li
                            v-for="child in link.children"
                            :key="child.url.toString()"
                        >
                            <LinkText :to="child.url" size="lg">
                                {{ child.text }}
                            </LinkText>
                        </li>
                    </ul>
                </template>
            </AccordionItem>

            <LinkText v-else :key="idx.toString()" :to="link.url" size="lg">
                {{ link.text }}
            </LinkText>
        </template>

        <LinkText to="/cart" size="lg">Корзина</LinkText>
    </AccordionContainer>
</template>

<style scoped></style>
