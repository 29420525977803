import { type ResourceCollection } from '../types/responses';
import { type Slide } from '../models/Slide';
import ApiService from './base/ApiService';

export default class SlideService extends ApiService {
    async get(): Promise<ResourceCollection<Slide>> {
        const endpoint = '/api/slides';
        const response = await this.fetch<ResourceCollection<Slide>>(endpoint);

        return response;
    }
}
