import type { Order } from '../models/Order';
import ApiService from './base/ApiService';
import type { Cart } from '~/types/cart';

export default class OrderService extends ApiService {
    async create(cart: Cart): Promise<Order> {
        const endpoint = '/api/orders';
        const response = await this.fetch<Order>(endpoint, {
            method: 'post',
            body: cart,
        });

        return response;
    }
}
