import { useCallTracking } from '~/composables/useCallTracking';

export default defineNuxtRouteMiddleware((to, from) => {
    if (import.meta.server) {
        return;
    }

    if (import.meta.env.DEV) {
        return;
    }

    useCallTracking();
});
