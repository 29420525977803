<script lang="ts" setup>
import { autoUpdate, flip, offset, shift, useFloating } from '@floating-ui/vue';
import Text from './Text.vue';
import { type AutocompleteOptions } from '~/layers/uikit/types/html';

interface Props {
    modelValue?: string | undefined;
    name: string;
    options: any[];
    pending?: boolean;
    autocomplete?: AutocompleteOptions;
    placeholder?: string;
    disabled?: boolean;
    readonly?: boolean;
    required?: boolean;
    clearable?: boolean;
    icon?: Component;
    onOptionSelect?: (option: any) => void;
}

interface Events {
    (e: 'update:modelValue', value?: string): void;
    (e: 'focus', value: FocusEvent): void;
    (e: 'blur', value: FocusEvent): void;
}

const props = defineProps<Props>();
const emit = defineEmits<Events>();

const router = useRouter();
const inputElement = ref(null);
const dropdownElement = ref(null);

const field = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit('update:modelValue', value);
    },
});

const isInputFocused = ref(false);

const { floatingStyles } = useFloating(inputElement, dropdownElement, {
    placement: 'bottom',
    whileElementsMounted: autoUpdate,
    middleware: [offset(7), flip(), shift()],
});

const isDropdownActive = computed(() => {
    return isInputFocused.value && field.value;
});

function onFocus(event: FocusEvent) {
    isInputFocused.value = true;
    emit('focus', event);
}

function onBlur(event: FocusEvent) {
    useDebounceFn(() => {
        isInputFocused.value = false;
    }, 100)();

    emit('blur', event);
}

function onSelect(option: any) {
    if (props.onOptionSelect) {
        props.onOptionSelect(option);

        return;
    }

    field.value = option;
}

function dismiss() {
    isInputFocused.value = false;
    field.value = undefined;
}

defineOptions({
    inheritAttrs: false,
});

watch(router.currentRoute, () => {
    dismiss();
});

onUnmounted(() => {
    dismiss();
});
</script>

<template>
    <Text
        ref="inputElement"
        v-model="field"
        v-bind="$attrs"
        :autocomplete="props.autocomplete"
        :name="props.name"
        :placeholder="props.placeholder"
        :disabled="props.disabled"
        :readonly="props.readonly"
        :required="props.required"
        :icon="props.icon"
        :clearable="props.clearable"
        @focus="onFocus"
        @blur="onBlur"
    />

    <div
        v-if="isDropdownActive"
        ref="dropdownElement"
        class="p-3 flex flex-col gap-2 bg-white border border-uk-orange rounded-xl z-40 divide-y w-full max-h-60 overflow-hidden overflow-y-auto overscroll-y-contain scrollbar-none"
        :style="floatingStyles"
    >
        <Spinner v-if="props.pending" :active="props.pending" />

        <template v-else>
            <template v-if="props.options.length > 0">
                <div
                    v-for="(option, idx) in props.options"
                    :key="idx"
                    class="first-of-type:pt-0 pt-2"
                    @click="onSelect(option)"
                >
                    <slot name="option" :option="option">
                        <TextCaption
                            class="block hover:text-uk-orange cursor-pointer transition-colors"
                        >
                            {{ option }}
                        </TextCaption>
                    </slot>
                </div>
            </template>

            <template v-else>
                <TextCaption class="text-uk-gray w-full">
                    Нет результатов
                </TextCaption>
            </template>
        </template>
    </div>
</template>

<style scoped></style>
