<script lang="ts" setup>
const { remoteConfig } = useConfigStore();

const openedAt = new Date(remoteConfig.CompanyEstablishedDate).getFullYear();
const currentYear = new Date().getFullYear();
</script>

<template>
    <div class="flex flex-col gap-y-8 md:flex-row justify-between">
        <div class="flex flex-col gap-4 md:gap-8 shrink-0 md:w-1/3 xl:w-auto">
            <LinkText to="/policy" size="sm">
                Политика конфиденциальности
            </LinkText>

            <div class="flex gap-4">
                <img
                    src="~/assets/images/payments/mir.svg"
                    width="50"
                    height="35"
                    alt="Оплата МИР"
                    loading="lazy"
                />
                <img
                    src="~/assets/images/payments/visa.svg"
                    width="50"
                    height="35"
                    alt="Оплата Visa"
                    loading="lazy"
                />
                <img
                    src="~/assets/images/payments/mastercard.svg"
                    width="50"
                    height="35"
                    alt="Оплата MasterCard"
                    loading="lazy"
                />
            </div>

            <TextCaption>
                © {{ openedAt }}-{{ currentYear }}. Все права защищены.
            </TextCaption>
        </div>

        <div class="flex flex-col gap-8 xl:max-w-2xl">
            <TextCaption>
                Мы используем файлы cookie, для персонализации сервисов и
                повышения удобства пользования сайтом. Если вы не согласны на их
                использование, поменяйте настройки браузера.
            </TextCaption>

            <TextCaption>
                Любые сведения, материалы и данные, опубликованные на сайте
                (включая стоимость курсов и прочее), представлены исключительно
                в информационных целях и ни при каких условиях не являются
                публичной офертой, определяемой положениями Статьи 437(2) ГК РФ
            </TextCaption>
        </div>
    </div>
</template>

<style scoped></style>
