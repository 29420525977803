<script lang="ts" setup>
import IconHome from '~/layers/uikit/components/Icon/Home.vue';
import IconHelm from '~/layers/uikit/components/Icon/Helm.vue';
import IconCart from '~/layers/uikit/components/Icon/Cart.vue';
import IconShip from '~/layers/uikit/components/Icon/Ship.vue';

const route = useRoute();
const cart = useCartStore();

const items = computed(() => [
    {
        key: 'home',
        label: 'Главная',
        icon: IconHome,
        to: '/',
        isActive: route.path === '/',
    },
    {
        key: 'courses',
        label: 'Курсы',
        icon: IconHelm,
        to: '/courses',
        isActive: route.path.startsWith('/courses'),
    },
    {
        key: 'shop',
        label: 'Магазин',
        icon: IconShip,
        to: '/shop',
        isActive: route.path.startsWith('/shop'),
    },
    {
        key: 'cart',
        label: 'Корзина',
        icon: IconCart,
        to: '/cart',
        isActive: route.path === '/cart',
        counter: cart.state.items.length,
    },
]);
</script>

<template>
    <ClientOnly>
        <div
            class="bg-white w-full h-16 p-5 fixed bottom-0 z-50 text-uk-gray flex justify-between sm:justify-around items-center md:hidden border-t"
        >
            <template v-for="item in items" :key="item.key">
                <NuxtLink
                    :to="item.to"
                    class="flex flex-col items-center gap-0"
                    :class="{ 'text-orange-500': item.isActive }"
                >
                    <div class="relative">
                        <component :is="item.icon" />
                        <small
                            v-if="item.counter"
                            class="bg-uk-blue text-white rounded-full px-1 min-w-[20px] text-center font-bold absolute -top-1 -right-3.5"
                        >
                            {{ item.counter }}
                        </small>
                    </div>

                    <span>{{ item.label }}</span>

                    <span
                        class="h-2 w-2 rounded-full"
                        :class="{ 'bg-uk-orange': item.isActive }"
                    ></span>
                </NuxtLink>
            </template>
        </div>
    </ClientOnly>
</template>

<style scoped></style>
