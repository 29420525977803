import type { RouteLocationRaw } from 'vue-router';
import { SearchResultType } from '~/layers/api/enum/SearchResultType';
import type { Job } from '~/layers/api/models/Careers';
import type {
    Course,
    CourseCategory,
    CourseEntry,
} from '~/layers/api/models/Courses';
import type { PhotoAlbum } from '~/layers/api/models/Photos';
import type { Article, News } from '~/layers/api/models/Posts';
import type { Product, ProductCategory } from '~/layers/api/models/Products';
import type { SearchResult } from '~/layers/api/models/Search';

export const useRouteBuilder = () => {
    const runtimeConfig = useRuntimeConfig();
    const route = useRoute();

    function canonical(): string {
        return `${runtimeConfig.public.baseUrl}${route.path}`;
    }

    function search(term: string): RouteLocationRaw {
        return {
            name: 'search',
            query: {
                q: term,
            },
        };
    }

    function toNews(item: News): RouteLocationRaw {
        return {
            name: 'news.view',
            params: {
                slug: item.slug,
            },
        };
    }

    function toArticle(article: Article): RouteLocationRaw {
        return {
            name: 'articles.view',
            params: {
                slug: article.slug,
            },
        };
    }

    function toPromotion(promo: News): RouteLocationRaw {
        return {
            name: 'promotions.view',
            params: {
                slug: promo.slug,
            },
        };
    }

    function toCourseCategory(category: CourseCategory): RouteLocationRaw {
        return {
            name: 'courses',
            query: {
                categories: [category.slug],
            },
        };
    }

    function toCourse(course: Course | CourseEntry): RouteLocationRaw {
        return {
            name: 'courses.view',
            params: {
                slug: course.slug,
            },
        };
    }

    function toJob(job: Job): RouteLocationRaw {
        return {
            name: 'careers.view',
            params: {
                id: job.id,
            },
        };
    }

    function toAlbum(photoAlbum: PhotoAlbum): RouteLocationRaw {
        return {
            name: 'gallery.album',
            params: {
                slug: photoAlbum.slug,
            },
        };
    }

    function toShopCategory(
        productCategory: ProductCategory
    ): RouteLocationRaw {
        return {
            name: 'shop.category',
            params: {
                slug: productCategory.slug,
            },
        };
    }

    function toProduct(product: Product): RouteLocationRaw {
        return {
            name: 'shop.product',
            params: {
                slug: product.slug,
            },
        };
    }

    function toSearchItem(item: SearchResult): RouteLocationRaw {
        switch (item.type) {
            case SearchResultType.Certificate:
            case SearchResultType.Course:
                return {
                    name: 'courses.view',
                    params: {
                        slug: item.slug,
                    },
                };
            case SearchResultType.Document:
                return {
                    name: 'documents',
                };
            default:
                return { name: 'search' };
        }
    }

    return {
        canonical,
        search,
        toNews,
        toArticle,
        toPromotion,
        toCourseCategory,
        toCourse,
        toJob,
        toAlbum,
        toShopCategory,
        toProduct,
        toSearchItem,
    };
};
