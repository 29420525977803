import type { Product, ProductCategory } from '../models/Products';
import type {
    PaginatedCollection,
    Resource,
    ResourceCollection,
} from '../types/responses';
import ApiService from './base/ApiService';

export default class ProductsService extends ApiService {
    async getCategories(): Promise<ResourceCollection<ProductCategory>> {
        const endpoint = '/api/categories';
        const response =
            await this.fetch<ResourceCollection<ProductCategory>>(endpoint);

        return response;
    }

    async getProducts(
        page: number,
        category?: ProductCategory
    ): Promise<PaginatedCollection<Product>> {
        const query = {
            category: category?.slug,
            page,
        };

        const endpoint = '/api/products';

        const response = await this.fetch<PaginatedCollection<Product>>(
            endpoint,
            { query }
        );

        return response;
    }

    async getProductById(id: number): Promise<Resource<Product>> {
        const endpoint = `/api/product/${id}`;
        const response = await this.fetch<Resource<Product>>(endpoint);

        return response;
    }

    async getProduct(slug: string): Promise<Resource<Product>> {
        const endpoint = `/api/products/${slug}`;
        const response = await this.fetch<Resource<Product>>(endpoint);

        return response;
    }

    async getSimilar(slug: string): Promise<ResourceCollection<Product>> {
        const endpoint = `/api/products/${slug}/similar`;
        const response =
            await this.fetch<ResourceCollection<Product>>(endpoint);

        return response;
    }
}
