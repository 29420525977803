import type { Question } from '../models/Questions';
import type { ResourceCollection } from '../types/responses';
import ApiService from './base/ApiService';

export default class QuestionService extends ApiService {
    async index(): Promise<ResourceCollection<Question>> {
        const endpoint = '/api/questions';
        const response =
            await this.fetch<ResourceCollection<Question>>(endpoint);

        return response;
    }
}
