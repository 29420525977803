<script lang="ts" setup>
interface Props {
    size?: number;
}

const props = withDefaults(defineProps<Props>(), {
    size: 1,
});
</script>

<template>
    <svg
        :width="21 * props.size"
        :height="21 * props.size"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_564_10614)">
            <path
                d="M13.3434 4.336C13.2538 4.336 13.1679 4.30041 13.1046 4.23705C13.0412 4.1737 13.0056 4.08777 13.0056 3.99817V1.85965C13.0056 1.72525 12.9522 1.59636 12.8572 1.50132C12.7621 1.40629 12.6332 1.3529 12.4988 1.3529C12.3644 1.3529 12.2356 1.40629 12.1405 1.50132C12.0455 1.59636 11.9921 1.72525 11.9921 1.85965V3.99817C11.9921 4.08777 11.9565 4.1737 11.8931 4.23705C11.8298 4.30041 11.7439 4.336 11.6543 4.336C11.5647 4.336 11.4787 4.30041 11.4154 4.23705C11.352 4.1737 11.3164 4.08777 11.3164 3.99817V1.85965C11.3158 1.70424 11.346 1.55025 11.4054 1.4066C11.4647 1.26296 11.5519 1.13251 11.662 1.02283C11.8274 0.857172 12.0382 0.744324 12.2678 0.698581C12.4973 0.652837 12.7353 0.676256 12.9515 0.76587C13.1678 0.855484 13.3526 1.00726 13.4825 1.20198C13.6124 1.39669 13.6816 1.62558 13.6813 1.85965V3.99817C13.6813 4.08777 13.6457 4.1737 13.5823 4.23705C13.519 4.30041 13.433 4.336 13.3434 4.336Z"
                fill="currentColor"
            />
            <path
                d="M13.3457 10.2787C13.2561 10.2787 13.1701 10.2431 13.1068 10.1798C13.0434 10.1164 13.0078 10.0305 13.0078 9.94088V6.40034C13.0078 6.31074 13.0434 6.22481 13.1068 6.16145C13.1701 6.09809 13.2561 6.0625 13.3457 6.0625C13.4353 6.0625 13.5212 6.09809 13.5845 6.16145C13.6479 6.22481 13.6835 6.31074 13.6835 6.40034V9.94088C13.6835 10.0305 13.6479 10.1164 13.5845 10.1798C13.5212 10.2431 13.4353 10.2787 13.3457 10.2787Z"
                fill="currentColor"
            />
            <path
                d="M11.6542 10.2787C11.5646 10.2787 11.4787 10.2431 11.4154 10.1798C11.352 10.1164 11.3164 10.0305 11.3164 9.94088V6.40034C11.3164 6.31074 11.352 6.22481 11.4154 6.16145C11.4787 6.09809 11.5646 6.0625 11.6542 6.0625C11.7438 6.0625 11.8298 6.09809 11.8931 6.16145C11.9565 6.22481 11.9921 6.31074 11.9921 6.40034V9.94088C11.9921 10.0305 11.9565 10.1164 11.8931 10.1798C11.8298 10.2431 11.7438 10.2787 11.6542 10.2787Z"
                fill="currentColor"
            />
            <path
                d="M12.4988 24.3228C12.1852 24.3228 11.8845 24.1983 11.6627 23.9765C11.441 23.7548 11.3164 23.454 11.3164 23.1404V21.0019C11.3164 20.9123 11.352 20.8264 11.4154 20.763C11.4787 20.6997 11.5646 20.6641 11.6542 20.6641C11.7438 20.6641 11.8298 20.6997 11.8931 20.763C11.9565 20.8264 11.9921 20.9123 11.9921 21.0019V23.1404C11.9921 23.2748 12.0455 23.4037 12.1405 23.4987C12.2355 23.5938 12.3644 23.6472 12.4988 23.6472C12.6332 23.6472 12.7621 23.5938 12.8572 23.4987C12.9522 23.4037 13.0056 23.2748 13.0056 23.1404V21.0019C13.0056 20.9123 13.0412 20.8264 13.1045 20.763C13.1679 20.6997 13.2538 20.6641 13.3434 20.6641C13.433 20.6641 13.519 20.6997 13.5823 20.763C13.6457 20.8264 13.6813 20.9123 13.6813 21.0019V23.1404C13.6819 23.2958 13.6517 23.4498 13.5923 23.5935C13.533 23.7371 13.4458 23.8676 13.3357 23.9772C13.1132 24.1982 12.8124 24.3225 12.4988 24.3228Z"
                fill="currentColor"
            />
            <path
                d="M11.6542 18.935C11.5646 18.935 11.4787 18.8994 11.4154 18.836C11.352 18.7727 11.3164 18.6867 11.3164 18.5971V15.0566C11.3164 14.967 11.352 14.8811 11.4154 14.8177C11.4787 14.7543 11.5646 14.7188 11.6542 14.7188C11.7438 14.7188 11.8298 14.7543 11.8931 14.8177C11.9565 14.8811 11.9921 14.967 11.9921 15.0566V18.5971C11.9921 18.6867 11.9565 18.7727 11.8931 18.836C11.8298 18.8994 11.7438 18.935 11.6542 18.935Z"
                fill="currentColor"
            />
            <path
                d="M13.3457 18.935C13.2561 18.935 13.1701 18.8994 13.1068 18.836C13.0434 18.7727 13.0078 18.6867 13.0078 18.5971V15.0566C13.0078 14.967 13.0434 14.8811 13.1068 14.8177C13.1701 14.7543 13.2561 14.7188 13.3457 14.7188C13.4353 14.7188 13.5212 14.7543 13.5845 14.8177C13.6479 14.8811 13.6835 14.967 13.6835 15.0566V18.5971C13.6835 18.6867 13.6479 18.7727 13.5845 18.836C13.5212 18.8994 13.4353 18.935 13.3457 18.935Z"
                fill="currentColor"
            />
            <path
                d="M3.9946 13.6813H1.85608C1.5424 13.6808 1.24157 13.5566 1.01892 13.3357C0.853245 13.1703 0.740391 12.9594 0.694658 12.7298C0.648925 12.5002 0.672372 12.2622 0.762028 12.046C0.851684 11.8297 1.00351 11.645 1.19828 11.5151C1.39305 11.3852 1.62198 11.3161 1.85608 11.3164H3.9946C4.0842 11.3164 4.17013 11.352 4.23349 11.4154C4.29684 11.4787 4.33244 11.5646 4.33244 11.6542C4.33244 11.7438 4.29684 11.8298 4.23349 11.8931C4.17013 11.9565 4.0842 11.9921 3.9946 11.9921H1.85608C1.72168 11.9921 1.59279 12.0455 1.49775 12.1405C1.40272 12.2355 1.34933 12.3644 1.34933 12.4988C1.34933 12.6332 1.40272 12.7621 1.49775 12.8572C1.59279 12.9522 1.72168 13.0056 1.85608 13.0056H3.9946C4.0842 13.0056 4.17013 13.0412 4.23349 13.1045C4.29684 13.1679 4.33244 13.2538 4.33244 13.3434C4.33244 13.433 4.29684 13.519 4.23349 13.5823C4.17013 13.6457 4.0842 13.6813 3.9946 13.6813Z"
                fill="currentColor"
            />
            <path
                d="M9.94088 11.9921H6.40034C6.31074 11.9921 6.22481 11.9565 6.16145 11.8931C6.09809 11.8298 6.0625 11.7438 6.0625 11.6542C6.0625 11.5646 6.09809 11.4787 6.16145 11.4154C6.22481 11.352 6.31074 11.3164 6.40034 11.3164H9.94088C10.0305 11.3164 10.1164 11.352 10.1798 11.4154C10.2431 11.4787 10.2787 11.5646 10.2787 11.6542C10.2787 11.7438 10.2431 11.8298 10.1798 11.8931C10.1164 11.9565 10.0305 11.9921 9.94088 11.9921Z"
                fill="currentColor"
            />
            <path
                d="M9.94088 13.6835H6.40034C6.31074 13.6835 6.22481 13.6479 6.16145 13.5845C6.09809 13.5212 6.0625 13.4353 6.0625 13.3457C6.0625 13.2561 6.09809 13.1701 6.16145 13.1068C6.22481 13.0434 6.31074 13.0078 6.40034 13.0078H9.94088C10.0305 13.0078 10.1164 13.0434 10.1798 13.1068C10.2431 13.1701 10.2787 13.2561 10.2787 13.3457C10.2787 13.4353 10.2431 13.5212 10.1798 13.5845C10.1164 13.6479 10.0305 13.6835 9.94088 13.6835Z"
                fill="currentColor"
            />
            <path
                d="M23.1404 13.6813H21.0019C20.9123 13.6813 20.8264 13.6457 20.763 13.5823C20.6997 13.519 20.6641 13.433 20.6641 13.3434C20.6641 13.2538 20.6997 13.1679 20.763 13.1045C20.8264 13.0412 20.9123 13.0056 21.0019 13.0056H23.1404C23.2748 13.0056 23.4037 12.9522 23.4987 12.8572C23.5938 12.7621 23.6472 12.6332 23.6472 12.4988C23.6472 12.3644 23.5938 12.2355 23.4987 12.1405C23.4037 12.0455 23.2748 11.9921 23.1404 11.9921H21.0019C20.9123 11.9921 20.8264 11.9565 20.763 11.8931C20.6997 11.8298 20.6641 11.7438 20.6641 11.6542C20.6641 11.5646 20.6997 11.4787 20.763 11.4154C20.8264 11.352 20.9123 11.3164 21.0019 11.3164H23.1404C23.4541 11.3169 23.7549 11.4411 23.9776 11.662C24.1433 11.8274 24.2561 12.0383 24.3018 12.2678C24.3476 12.4974 24.3241 12.7354 24.2345 12.9517C24.1448 13.1679 23.993 13.3527 23.7982 13.4826C23.6035 13.6125 23.3745 13.6816 23.1404 13.6813Z"
                fill="currentColor"
            />
            <path
                d="M18.5971 13.6835H15.0566C14.967 13.6835 14.8811 13.6479 14.8177 13.5845C14.7543 13.5212 14.7188 13.4353 14.7188 13.3457C14.7188 13.2561 14.7543 13.1701 14.8177 13.1068C14.8811 13.0434 14.967 13.0078 15.0566 13.0078H18.5971C18.6867 13.0078 18.7727 13.0434 18.836 13.1068C18.8994 13.1701 18.935 13.2561 18.935 13.3457C18.935 13.4353 18.8994 13.5212 18.836 13.5845C18.7727 13.6479 18.6867 13.6835 18.5971 13.6835Z"
                fill="currentColor"
            />
            <path
                d="M18.5971 11.9921H15.0566C14.967 11.9921 14.8811 11.9565 14.8177 11.8931C14.7543 11.8298 14.7188 11.7438 14.7188 11.6542C14.7188 11.5646 14.7543 11.4787 14.8177 11.4154C14.8811 11.352 14.967 11.3164 15.0566 11.3164H18.5971C18.6867 11.3164 18.7727 11.352 18.836 11.4154C18.8994 11.4787 18.935 11.5646 18.935 11.6542C18.935 11.7438 18.8994 11.8298 18.836 11.8931C18.7727 11.9565 18.6867 11.9921 18.5971 11.9921Z"
                fill="currentColor"
            />
            <path
                d="M5.89045 7.42305C5.80086 7.42303 5.71494 7.38742 5.6516 7.32406L4.13808 5.81055C4.0273 5.70118 3.93938 5.57086 3.87946 5.42718C3.81954 5.2835 3.78881 5.12934 3.78906 4.97366C3.78932 4.81799 3.82054 4.66392 3.88093 4.52044C3.94131 4.37695 4.02965 4.24692 4.14079 4.13791C4.2496 4.028 4.37923 3.94088 4.5221 3.88166C4.66497 3.82243 4.81822 3.79229 4.97288 3.79298C5.12847 3.79244 5.28261 3.82298 5.42625 3.88279C5.56989 3.9426 5.70014 4.03048 5.80937 4.14129L7.32153 5.65379C7.38307 5.71751 7.41712 5.80285 7.41635 5.89143C7.41558 5.98001 7.38005 6.06474 7.31741 6.12738C7.25478 6.19002 7.17004 6.22555 7.08146 6.22632C6.99288 6.22709 6.90754 6.19303 6.84383 6.13149L5.33031 4.61798C5.28371 4.57051 5.22808 4.53284 5.16669 4.50719C5.1053 4.48155 5.03941 4.46844 4.97288 4.46866C4.83902 4.47041 4.71114 4.52436 4.61648 4.61902C4.52183 4.71368 4.46787 4.84156 4.46612 4.97541C4.46636 5.04228 4.47982 5.10843 4.50574 5.17007C4.53166 5.2317 4.56952 5.2876 4.61714 5.33453L6.13065 6.84805C6.17788 6.89529 6.21005 6.95549 6.22308 7.02101C6.23611 7.08653 6.22942 7.15445 6.20385 7.21617C6.17829 7.2779 6.135 7.33066 6.07945 7.36778C6.02391 7.4049 5.95861 7.42472 5.8918 7.42474L5.89045 7.42305Z"
                fill="currentColor"
            />
            <path
                d="M11.2868 10.4332C11.1972 10.4332 11.1113 10.3976 11.0479 10.3342L8.54454 7.83084C8.51228 7.79968 8.48654 7.7624 8.46883 7.72118C8.45113 7.67996 8.44181 7.63563 8.44142 7.59077C8.44103 7.54592 8.44958 7.50143 8.46656 7.45991C8.48355 7.41839 8.50864 7.38067 8.54036 7.34895C8.57208 7.31723 8.6098 7.29214 8.65132 7.27516C8.69284 7.25817 8.73732 7.24962 8.78218 7.25001C8.82704 7.2504 8.87137 7.25972 8.91259 7.27743C8.9538 7.29513 8.99108 7.32087 9.02225 7.35314L11.5256 9.85652C11.5729 9.90376 11.605 9.96395 11.6181 10.0295C11.6311 10.095 11.6244 10.1629 11.5988 10.2246C11.5733 10.2864 11.53 10.3391 11.4744 10.3762C11.4189 10.4134 11.3536 10.4332 11.2868 10.4332Z"
                fill="currentColor"
            />
            <path
                d="M10.0954 11.6246C10.0058 11.6246 9.91986 11.589 9.85652 11.5256L7.35314 9.02225C7.32087 8.99108 7.29513 8.9538 7.27743 8.91259C7.25972 8.87137 7.2504 8.82704 7.25001 8.78218C7.24962 8.73732 7.25817 8.69284 7.27516 8.65132C7.29214 8.6098 7.31723 8.57208 7.34895 8.54036C7.38067 8.50864 7.41839 8.48355 7.45991 8.46656C7.50143 8.44958 7.54592 8.44103 7.59077 8.44142C7.63563 8.44181 7.67996 8.45113 7.72118 8.46883C7.7624 8.48654 7.79968 8.51228 7.83084 8.54454L10.3342 11.0479C10.3815 11.0952 10.4136 11.1554 10.4266 11.2209C10.4397 11.2864 10.433 11.3543 10.4074 11.416C10.3819 11.4778 10.3386 11.5305 10.283 11.5677C10.2275 11.6048 10.1622 11.6246 10.0954 11.6246Z"
                fill="currentColor"
            />
            <path
                d="M20.0252 21.2079C19.8696 21.2084 19.7156 21.1779 19.572 21.1181C19.4284 21.0583 19.2982 20.9704 19.189 20.8596L17.6769 19.3471C17.6153 19.2834 17.5813 19.1981 17.582 19.1095C17.5828 19.0209 17.6183 18.9362 17.681 18.8735C17.7436 18.8109 17.8284 18.7754 17.9169 18.7746C18.0055 18.7738 18.0909 18.8079 18.1546 18.8694L19.6681 20.3829C19.7147 20.4304 19.7703 20.4681 19.8317 20.4937C19.8931 20.5194 19.959 20.5325 20.0255 20.5323C20.1593 20.5309 20.2873 20.4773 20.3822 20.383C20.4771 20.2886 20.5314 20.161 20.5336 20.0272C20.5334 19.9603 20.5199 19.8942 20.494 19.8326C20.4681 19.7709 20.4302 19.715 20.3826 19.6681L18.8691 18.1546C18.8076 18.0909 18.7735 18.0055 18.7743 17.9169C18.775 17.8284 18.8106 17.7436 18.8732 17.681C18.9358 17.6183 19.0206 17.5828 19.1092 17.582C19.1977 17.5813 19.2831 17.6153 19.3468 17.6769L20.8603 19.1904C20.9711 19.2997 21.059 19.4301 21.1189 19.5737C21.1789 19.7174 21.2096 19.8716 21.2093 20.0273C21.2091 20.1829 21.1779 20.337 21.1175 20.4805C21.0571 20.624 20.9687 20.754 20.8576 20.863C20.7488 20.9729 20.6192 21.06 20.4763 21.1193C20.3334 21.1785 20.1798 21.2086 20.0252 21.2079Z"
                fill="currentColor"
            />
            <path
                d="M16.216 17.7458C16.1264 17.7457 16.0405 17.7101 15.9772 17.6468L13.4738 15.1434C13.4104 15.0799 13.3749 14.9939 13.375 14.9042C13.3751 14.8145 13.4108 14.7285 13.4743 14.6652C13.5378 14.6018 13.6238 14.5663 13.7135 14.5664C13.8032 14.5665 13.8892 14.6022 13.9525 14.6657L16.4559 17.1691C16.5032 17.2164 16.5354 17.2767 16.5484 17.3423C16.5614 17.4079 16.5546 17.4759 16.5289 17.5377C16.5032 17.5994 16.4598 17.6522 16.4041 17.6892C16.3484 17.7263 16.2829 17.746 16.216 17.7458Z"
                fill="currentColor"
            />
            <path
                d="M17.4074 16.5538C17.3178 16.5538 17.2319 16.5182 17.1685 16.4548L14.6651 13.9514C14.6036 13.8877 14.5696 13.8024 14.5703 13.7138C14.5711 13.6252 14.6066 13.5405 14.6693 13.4779C14.7319 13.4152 14.8166 13.3797 14.9052 13.3789C14.9938 13.3781 15.0791 13.4122 15.1429 13.4737L17.6462 15.9771C17.6935 16.0244 17.7256 16.0846 17.7387 16.1501C17.7517 16.2156 17.745 16.2835 17.7194 16.3452C17.6939 16.407 17.6506 16.4597 17.595 16.4969C17.5395 16.534 17.4742 16.5538 17.4074 16.5538Z"
                fill="currentColor"
            />
            <path
                d="M14.9042 11.6241C14.8374 11.6241 14.7721 11.6043 14.7165 11.5672C14.661 11.53 14.6177 11.4773 14.5921 11.4156C14.5666 11.3538 14.5599 11.2859 14.5729 11.2204C14.5859 11.1549 14.6181 11.0947 14.6653 11.0474L17.1687 8.54405C17.2324 8.48251 17.3178 8.44846 17.4063 8.44923C17.4949 8.45 17.5796 8.48553 17.6423 8.54817C17.7049 8.61081 17.7405 8.69554 17.7412 8.78412C17.742 8.8727 17.7079 8.95804 17.6464 9.02176L15.143 11.5251C15.0797 11.5885 14.9938 11.6241 14.9042 11.6241Z"
                fill="currentColor"
            />
            <path
                d="M13.7128 10.4332C13.646 10.4332 13.5807 10.4134 13.5251 10.3762C13.4696 10.3391 13.4263 10.2864 13.4007 10.2246C13.3751 10.1629 13.3685 10.095 13.3815 10.0295C13.3945 9.96395 13.4267 9.90376 13.4739 9.85652L15.9773 7.35314C16.0085 7.32087 16.0457 7.29513 16.087 7.27743C16.1282 7.25972 16.1725 7.2504 16.2174 7.25001C16.2622 7.24962 16.3067 7.25817 16.3482 7.27516C16.3897 7.29214 16.4275 7.31723 16.4592 7.34895C16.4909 7.38067 16.516 7.41839 16.533 7.45991C16.55 7.50143 16.5585 7.54592 16.5581 7.59077C16.5577 7.63563 16.5484 7.67996 16.5307 7.72118C16.513 7.7624 16.4873 7.79968 16.455 7.83084L13.9516 10.3342C13.8883 10.3976 13.8024 10.4332 13.7128 10.4332Z"
                fill="currentColor"
            />
            <path
                d="M19.1083 7.42305C19.0415 7.42304 18.9762 7.40321 18.9206 7.36609C18.8651 7.32897 18.8218 7.27621 18.7962 7.21449C18.7707 7.15276 18.764 7.08485 18.777 7.01932C18.79 6.9538 18.8222 6.89361 18.8694 6.84636L20.3829 5.33285C20.4306 5.28591 20.4684 5.23001 20.4943 5.16838C20.5203 5.10674 20.5337 5.04059 20.534 4.97373C20.5341 4.9079 20.5211 4.8427 20.4956 4.782C20.4702 4.7213 20.4328 4.66633 20.3857 4.62035C20.3388 4.57226 20.2828 4.53405 20.221 4.508C20.1591 4.48194 20.0926 4.46856 20.0255 4.46866C19.9593 4.46838 19.8936 4.48136 19.8325 4.50683C19.7713 4.5323 19.7159 4.56975 19.6694 4.61697L18.1546 6.1315C18.0909 6.19304 18.0055 6.22709 17.9169 6.22632C17.8284 6.22555 17.7436 6.19002 17.681 6.12738C17.6183 6.06474 17.5828 5.98001 17.582 5.89143C17.5813 5.80285 17.6153 5.71751 17.6769 5.65379L19.1904 4.14028C19.2995 4.02973 19.4295 3.94206 19.5729 3.88242C19.7163 3.82279 19.8702 3.79238 20.0255 3.79298C20.2599 3.79186 20.4893 3.86058 20.6844 3.99036C20.8796 4.12015 21.0317 4.30512 21.1213 4.52169C21.2109 4.73826 21.2339 4.97661 21.1875 5.20634C21.1411 5.43607 21.0273 5.64677 20.8607 5.81156L19.3471 7.32508C19.2836 7.38807 19.1977 7.4233 19.1083 7.42305Z"
                fill="currentColor"
            />
            <path
                d="M8.78308 17.7452C8.71627 17.7452 8.65097 17.7254 8.59542 17.6883C8.53988 17.6511 8.49659 17.5984 8.47103 17.5367C8.44546 17.4749 8.43877 17.407 8.4518 17.3415C8.46483 17.276 8.49699 17.2158 8.54423 17.1685L11.0476 14.6651C11.1113 14.6036 11.1967 14.5696 11.2852 14.5703C11.3738 14.5711 11.4586 14.6066 11.5212 14.6693C11.5838 14.7319 11.6194 14.8166 11.6201 14.9052C11.6209 14.9938 11.5868 15.0791 11.5253 15.1429L9.02193 17.6462C8.95859 17.7096 8.87267 17.7452 8.78308 17.7452Z"
                fill="currentColor"
            />
            <path
                d="M7.59167 16.5539C7.52486 16.5538 7.45956 16.534 7.40402 16.4969C7.34847 16.4598 7.30518 16.407 7.27962 16.3453C7.25405 16.2836 7.24736 16.2157 7.26039 16.1501C7.27342 16.0846 7.30559 16.0244 7.35282 15.9772L9.8562 13.4738C9.91968 13.4104 10.0057 13.3749 10.0954 13.375C10.1851 13.3751 10.2711 13.4108 10.3344 13.4743C10.3978 13.5378 10.4333 13.6238 10.4332 13.7135C10.4331 13.8032 10.3974 13.8892 10.3339 13.9525L7.83052 16.4559C7.76702 16.5189 7.68112 16.5541 7.59167 16.5539Z"
                fill="currentColor"
            />
            <path
                d="M4.97443 21.209C4.74006 21.2101 4.51067 21.1414 4.31551 21.0116C4.12035 20.8818 3.96828 20.6968 3.87868 20.4803C3.78908 20.2637 3.76601 20.0253 3.81243 19.7956C3.85885 19.5659 3.97264 19.3552 4.13929 19.1904L5.65281 17.6769C5.71652 17.6153 5.80186 17.5813 5.89044 17.582C5.97902 17.5828 6.06376 17.6183 6.12639 17.681C6.18903 17.7436 6.22456 17.8284 6.22533 17.9169C6.2261 18.0055 6.19205 18.0909 6.13051 18.1546L4.617 19.6681C4.56938 19.715 4.53152 19.7709 4.5056 19.8326C4.47968 19.8942 4.46622 19.9603 4.46598 20.0272C4.4658 20.093 4.47883 20.1582 4.50431 20.2189C4.52978 20.2796 4.56718 20.3346 4.61429 20.3806C4.66112 20.4287 4.71713 20.4669 4.77899 20.4929C4.84085 20.519 4.90731 20.5324 4.97443 20.5323C5.04067 20.5325 5.1063 20.5196 5.16746 20.4941C5.22861 20.4686 5.28405 20.4312 5.33051 20.384L6.84538 18.8694C6.90909 18.8079 6.99443 18.7738 7.08301 18.7746C7.17159 18.7754 7.25632 18.8109 7.31896 18.8735C7.3816 18.9362 7.41713 19.0209 7.4179 19.1095C7.41867 19.1981 7.38462 19.2834 7.32308 19.3471L5.80956 20.8607C5.70056 20.9714 5.57054 21.0592 5.42713 21.1191C5.28372 21.1789 5.12981 21.2094 4.97443 21.209Z"
                fill="currentColor"
            />
            <path
                d="M12.4988 15.5289C11.8995 15.5289 11.3137 15.3512 10.8154 15.0182C10.3171 14.6853 9.92874 14.212 9.6994 13.6584C9.47006 13.1047 9.41006 12.4955 9.52697 11.9077C9.64389 11.3199 9.93248 10.78 10.3562 10.3562C10.78 9.93248 11.3199 9.64389 11.9077 9.52697C12.4955 9.41006 13.1047 9.47006 13.6584 9.6994C14.212 9.92874 14.6853 10.3171 15.0182 10.8154C15.3512 11.3137 15.5289 11.8995 15.5289 12.4988C15.5279 13.3021 15.2083 14.0723 14.6403 14.6403C14.0723 15.2083 13.3021 15.5279 12.4988 15.5289ZM12.4988 10.1444C12.0332 10.1444 11.578 10.2825 11.1908 10.5412C10.8036 10.7999 10.5018 11.1676 10.3236 11.5978C10.1454 12.028 10.0988 12.5014 10.1897 12.9581C10.2805 13.4148 10.5047 13.8344 10.834 14.1636C11.1633 14.4929 11.5828 14.7171 12.0395 14.808C12.4962 14.8988 12.9696 14.8522 13.3998 14.674C13.83 14.4958 14.1977 14.194 14.4564 13.8068C14.7151 13.4197 14.8532 12.9645 14.8532 12.4988C14.8525 11.8746 14.6042 11.2762 14.1628 10.8348C13.7215 10.3934 13.123 10.1451 12.4988 10.1444Z"
                fill="currentColor"
            />
            <path
                d="M12.5007 18.9936C11.2165 18.9936 9.96121 18.6128 8.89346 17.8994C7.8257 17.1859 6.99349 16.1719 6.50206 14.9854C6.01063 13.799 5.88204 12.4935 6.13257 11.234C6.3831 9.97452 7.00149 8.81759 7.90954 7.90954C8.81759 7.00149 9.97452 6.3831 11.234 6.13257C12.4935 5.88204 13.799 6.01063 14.9854 6.50206C16.1719 6.99349 17.1859 7.8257 17.8994 8.89346C18.6128 9.96121 18.9936 11.2165 18.9936 12.5007C18.9917 14.2221 18.307 15.8725 17.0897 17.0897C15.8725 18.307 14.2221 18.9917 12.5007 18.9936ZM12.5007 6.68349C11.3502 6.68349 10.2255 7.02467 9.26884 7.66387C8.3122 8.30308 7.56659 9.2116 7.1263 10.2746C6.68601 11.3375 6.57081 12.5072 6.79527 13.6356C7.01973 14.764 7.57376 15.8006 8.38732 16.6141C9.20087 17.4277 10.2374 17.9817 11.3658 18.2062C12.4943 18.4306 13.6639 18.3154 14.7269 17.8751C15.7898 17.4348 16.6984 16.6892 17.3376 15.7326C17.9768 14.776 18.318 13.6513 18.318 12.5007C18.3162 10.9584 17.7027 9.47985 16.6121 8.38929C15.5216 7.29874 14.043 6.68528 12.5007 6.68349Z"
                fill="currentColor"
            />
            <path
                d="M12.4996 21.3821C10.7428 21.3821 9.02552 20.8611 7.56481 19.8851C6.1041 18.9091 4.96562 17.5218 4.29333 15.8988C3.62104 14.2757 3.44513 12.4898 3.78786 10.7667C4.1306 9.04373 4.97657 7.46103 6.2188 6.2188C7.46103 4.97657 9.04373 4.1306 10.7667 3.78786C12.4898 3.44513 14.2757 3.62104 15.8988 4.29333C17.5218 4.96562 18.9091 6.1041 19.8851 7.56481C20.8611 9.02552 21.3821 10.7428 21.3821 12.4996C21.3794 14.8546 20.4427 17.1123 18.7775 18.7775C17.1123 20.4427 14.8546 21.3794 12.4996 21.3821ZM12.4996 4.29287C10.8765 4.29287 9.28979 4.77419 7.94019 5.67596C6.5906 6.57773 5.53872 7.85945 4.91757 9.35903C4.29642 10.8586 4.1339 12.5087 4.45056 14.1007C4.76722 15.6926 5.54884 17.1549 6.69657 18.3027C7.84431 19.4504 9.30661 20.232 10.8986 20.5487C12.4905 20.8654 14.1406 20.7028 15.6402 20.0817C17.1398 19.4605 18.4215 18.4086 19.3233 17.0591C20.2251 15.7095 20.7064 14.1228 20.7064 12.4996C20.704 10.3238 19.8386 8.23778 18.3 6.69924C16.7615 5.16069 14.6755 4.29528 12.4996 4.29287Z"
                fill="currentColor"
            />
        </g>
    </svg>
</template>

<style scoped></style>
