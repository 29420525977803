import revive_payload_client_4sVQNw7RlN from "/home/runner/work/flagman-frontstore/flagman-frontstore/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/runner/work/flagman-frontstore/flagman-frontstore/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/runner/work/flagman-frontstore/flagman-frontstore/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_7pzUtwM1Zj from "/home/runner/work/flagman-frontstore/flagman-frontstore/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_yVLowv6hDl from "/home/runner/work/flagman-frontstore/flagman-frontstore/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/runner/work/flagman-frontstore/flagman-frontstore/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/home/runner/work/flagman-frontstore/flagman-frontstore/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/runner/work/flagman-frontstore/flagman-frontstore/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/runner/work/flagman-frontstore/flagman-frontstore/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import nuxt_plugin_03YjkxYbK5 from "/home/runner/work/flagman-frontstore/flagman-frontstore/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.mjs";
import plugin_tbFNToZNim from "/home/runner/work/flagman-frontstore/flagman-frontstore/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_client_LcKgStRyi6 from "/home/runner/work/flagman-frontstore/flagman-frontstore/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import plugin_vfCt6Q18Tc from "/home/runner/work/flagman-frontstore/flagman-frontstore/node_modules/nuxt-auth-sanctum/dist/runtime/plugin.js";
import chunk_reload_client_UciE0i6zes from "/home/runner/work/flagman-frontstore/flagman-frontstore/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import api_IWoNmbqQ02 from "/home/runner/work/flagman-frontstore/flagman-frontstore/layers/api/plugins/api.ts";
import maskito_rrUpwOZmar from "/home/runner/work/flagman-frontstore/flagman-frontstore/layers/uikit/plugins/maskito.ts";
import social_share_LoAbyc8PQp from "/home/runner/work/flagman-frontstore/flagman-frontstore/layers/uikit/plugins/social-share.ts";
import swiper_client_TUxcIvTV6E from "/home/runner/work/flagman-frontstore/flagman-frontstore/layers/uikit/plugins/swiper.client.ts";
import toast_client_pj9olO8UnL from "/home/runner/work/flagman-frontstore/flagman-frontstore/layers/uikit/plugins/toast.client.ts";
import zod_i18n_4f5euMa2Bv from "/home/runner/work/flagman-frontstore/flagman-frontstore/layers/uikit/plugins/zod-i18n.ts";
import luxon_5MWj2Lijxd from "/home/runner/work/flagman-frontstore/flagman-frontstore/plugins/luxon.ts";
import text_highlight_wlHOEfdeHv from "/home/runner/work/flagman-frontstore/flagman-frontstore/plugins/text-highlight.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_7pzUtwM1Zj,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  nuxt_plugin_03YjkxYbK5,
  plugin_tbFNToZNim,
  plugin_client_LcKgStRyi6,
  plugin_vfCt6Q18Tc,
  chunk_reload_client_UciE0i6zes,
  api_IWoNmbqQ02,
  maskito_rrUpwOZmar,
  social_share_LoAbyc8PQp,
  swiper_client_TUxcIvTV6E,
  toast_client_pj9olO8UnL,
  zod_i18n_4f5euMa2Bv,
  luxon_5MWj2Lijxd,
  text_highlight_wlHOEfdeHv
]