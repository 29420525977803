import { type $Fetch } from 'ofetch';
import OrderService from '../services/OrderService';
import SearchService from '../services/SearchService';
import CartService from '../services/CartService';
import PromocodeService from '../services/PromocodeService';
import QuestionService from '../services/QuestionService';
import ProductService from '../services/ProductService';
import DocumentsService from '../services/DocumentsService';
import PhotoService from '../services/PhotoService';
import EmployeeService from '../services/EmployeeService';
import CareersService from '../services/CareersService';
import PromotionService from '../services/PromotionService';
import ArticleService from '../services/ArticleService';
import CustomerRequestService from '../services/CustomerRequestService';
import CourseService from '../services/CourseService';
import NewsService from '../services/NewsService';
import SlideService from '../services/SlideService';
import MenuService from '../services/MenuService';
import { type ServiceProvider } from '../types/provider';
import RemoteConfigService from '../services/RemoteConfigService';

/**
 * Creates an instance of API service provider with the given client
 *
 * @param client OFetch client instance
 * @returns Instance of ServiceProvider
 */
function createServiceProvider(client: $Fetch): ServiceProvider {
    return {
        // API Services (system comment for code generator, do not remove)
        orderService: new OrderService(client),
        searchService: new SearchService(client),
        cartService: new CartService(client),
        promocodeService: new PromocodeService(client),
        questionService: new QuestionService(client),
        productService: new ProductService(client),
        documentsService: new DocumentsService(client),
        photoService: new PhotoService(client),
        employeeService: new EmployeeService(client),
        careersService: new CareersService(client),
        promotionService: new PromotionService(client),
        articleService: new ArticleService(client),
        customerRequestService: new CustomerRequestService(client),
        courseService: new CourseService(client),
        newsService: new NewsService(client),
        slideService: new SlideService(client),
        menuService: new MenuService(client),
        remoteConfigService: new RemoteConfigService(client),
    };
}

export default defineNuxtPlugin((nuxtApp) => {
    const client = useSanctumClient();
    const api = createServiceProvider(client);

    return {
        provide: {
            api,
        },
    };
});
