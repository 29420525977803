<script lang="ts" setup>
import Caption from '../Text/Caption.vue';
import Body from '../Text/Body.vue';

interface Props {
    rounded?: boolean;
    disabled?: boolean;
    size?: 'sm' | 'md';
    variant?:
        | 'primary'
        | 'primary-border'
        | 'secondary'
        | 'secondary-border'
        | 'primary-inverse'
        | 'secondary-inverse'
        | 'primary-inverse-border'
        | 'secondary-inverse-border';
}

interface Events {
    (e: 'click', value: MouseEvent): void;
}

const props = withDefaults(defineProps<Props>(), {
    rounded: false,
    size: 'md',
    variant: 'primary',
});

const emit = defineEmits<Events>();

const contentComponent = computed(() => {
    return props.size === 'sm' ? Caption : Body;
});

const buttonClasses = computed(() => {
    return {
        'rounded-lg': !props.rounded && props.size === 'sm',
        'rounded-xl': !props.rounded && props.size === 'md',
        'rounded-full': props.rounded,

        'px-2 py-2': props.size === 'sm',
        'px-5 py-2.5': props.size === 'md',

        'cursor-not-allowed opacity-60': props.disabled,

        'text-white border-transparent bg-uk-orange enabled:hover:text-uk-orange enabled:hover:bg-white':
            props.variant === 'primary',

        'text-white border-uk-orange bg-uk-orange enabled:hover:text-uk-orange enabled:hover:bg-white':
            props.variant === 'primary-border',

        'text-uk-orange border-transparent bg-white enabled:hover:text-white enabled:hover:bg-uk-orange':
            props.variant === 'primary-inverse',

        'text-uk-orange border-uk-orange bg-white enabled:hover:text-white enabled:hover:bg-uk-orange':
            props.variant === 'primary-inverse-border',

        'text-white border-transparent bg-uk-blue enabled:hover:text-uk-blue enabled:hover:bg-white':
            props.variant === 'secondary',

        'text-white border-uk-blue bg-uk-blue enabled:hover:text-uk-blue enabled:hover:bg-white':
            props.variant === 'secondary-border',

        'text-uk-blue border-transparent bg-white enabled:hover:text-white enabled:hover:bg-uk-blue':
            props.variant === 'secondary-inverse',

        'text-uk-blue border-uk-blue bg-white enabled:hover:text-white enabled:hover:bg-uk-blue':
            props.variant === 'secondary-inverse-border',
    };
});
</script>

<template>
    <button
        :class="buttonClasses"
        :disabled="props.disabled"
        class="border box-border transition-colors flex items-center justify-center"
        @click="emit('click', $event)"
    >
        <component :is="contentComponent">
            <slot />
        </component>
    </button>
</template>

<style scoped></style>
