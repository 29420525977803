import '../scss/toast.scss';
import * as vt from 'vue-toastification';

export default defineNuxtPlugin((nuxtApp) => {
    const router = useRouter();

    const notificationOptions = {
        transition: 'Vue-Toastification__bounce',
        maxToasts: 10,
        newestOnTop: true,
        position: 'bottom-right',
        timeout: 3500,
        closeOnClick: true,
        pauseOnFocusLoss: false,
        pauseOnHover: false,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: false,
        icon: false,
        rtl: false,

        onMounted: (_: any, toastApp: any) => {
            toastApp.use(router);
        },
    };

    nuxtApp.vueApp.use(vt.default, notificationOptions);

    return {
        provide: {
            toast: vt.useToast(),
        },
    };
});
