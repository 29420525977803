<script lang="ts" setup>
import { type RouteLocationRaw } from 'vue-router';
import Caption from '../Text/Caption.vue';
import Body from '../Text/Body.vue';
import Subtitle from '../Text/Subtitle.vue';

interface Props {
    to: string | RouteLocationRaw;
    size?: 'sm' | 'md' | 'lg';
    target?: string;
}

const props = withDefaults(defineProps<Props>(), {
    size: 'md',
    target: undefined,
});

const contentComponent = computed(() => {
    const size = props.size;
    const map = {
        sm: Caption,
        md: Body,
        lg: Subtitle,
    };

    return map[size];
});

const isExternal = computed(() => {
    return typeof props.to === 'string' && props.to.startsWith('http');
});
</script>

<template>
    <NuxtLink
        :to="props.to"
        :external="isExternal"
        :target="props.target"
        class="transition-colors hover:text-uk-orange inline"
    >
        <component :is="contentComponent">
            <slot />
        </component>
    </NuxtLink>
</template>

<style scoped></style>
