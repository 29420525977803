<script lang="ts" setup>
const { remoteConfig } = useConfigStore();

const cleanPhoneNumber = computed(() =>
    remoteConfig.CompanyPhone?.replace(/\D/g, '')
);
</script>

<template>
    <div class="flex flex-col gap-6">
        <div class="flex flex-col w-fit">
            <NuxtLink external :to="`tel:${cleanPhoneNumber}`">
                <TextBody bold>
                    <span class="zphone">{{ remoteConfig.CompanyPhone }}</span>
                </TextBody>
            </NuxtLink>
            <TextCaption>Круглосуточная поддержка</TextCaption>
        </div>

        <NavigationSocial />

        <TextCaption class="max-w-[10rem] xl:max-w-[13rem]">
            {{ remoteConfig?.CompanyAddress }}
        </TextCaption>
    </div>
</template>

<style scoped></style>
