import { objectToFormData } from 'object-form-encoder';
import type { Job, JobCategory } from '../models/Careers';
import type { Resource, ResourceCollection } from '../types/responses';
import ApiService from './base/ApiService';

export default class CareersService extends ApiService {
    async index(): Promise<ResourceCollection<JobCategory>> {
        const endpoint = '/api/jobs';
        const response =
            await this.fetch<ResourceCollection<JobCategory>>(endpoint);

        return response;
    }

    async get(jobId: number): Promise<Resource<Job>> {
        const endpoint = `/api/jobs/${jobId}`;
        const response = await this.fetch<Resource<Job>>(endpoint);

        return response;
    }

    async apply(payload: Record<string, any>): Promise<void> {
        const endpoint = '/api/jobs/apply';
        const formData = objectToFormData(payload);

        const response = await this.fetch(endpoint, {
            method: 'post',
            body: formData,
        });

        return response;
    }
}
