import { init } from 'i18next';
import { z } from 'zod';
import { zodI18nMap } from 'zod-i18n-map';
import translation from 'zod-i18n-map/locales/ru/zod.json';

export default defineNuxtPlugin(() => {
    const runtimeConfig = useRuntimeConfig();

    init({
        lng: runtimeConfig.public.locale,
        resources: {
            ru: { zod: translation },
        },
    });

    z.setErrorMap(zodI18nMap);
});
