<script lang="ts" setup></script>

<template>
    <section class="lg:hidden bg-white text-uk-blue py-4 sticky z-40 top-0">
        <Container class="flex items-center justify-between">
            <Logo />

            <div class="flex gap-4 items-center">
                <NavigationMobileOverlay />
            </div>
        </Container>
    </section>
</template>

<style scoped></style>
