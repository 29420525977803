import type { RemoteConfig } from '../models/RemoteConfig';
import ApiService from './base/ApiService';

export default class RemoteConfigService extends ApiService {
    async get(): Promise<RemoteConfig> {
        const endpoint = '/api/config';
        const response = await this.fetch<RemoteConfig>(endpoint);

        return response;
    }
}
