<script lang="ts" setup>
const { remoteConfig } = useConfigStore();

const cleanPhoneNumber = computed(() =>
    remoteConfig.CompanyPhone?.replace(/\D/g, '')
);
</script>

<template>
    <div class="flex flex-col min-w-fit">
        <NuxtLink external :to="`tel:${cleanPhoneNumber}`">
            <TextMenu bold>
                <span class="zphone">
                    {{ remoteConfig.CompanyPhone }}
                </span>
            </TextMenu>
        </NuxtLink>
        <TextMenu>Круглосуточная поддержка</TextMenu>
    </div>
</template>

<style scoped></style>
