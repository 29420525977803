import { Environment } from '~/enum/env';

export const useEnvironment = () => {
    const env = process.env.NODE_ENV as Environment;

    return {
        isProduction: env === Environment.Production,
        isDevelopment: env === Environment.Development,
    };
};
