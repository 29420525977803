<script lang="ts" setup></script>

<template>
    <svg
        data-name="Layer 1"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        width="120"
        class="shrink-0"
    >
        <rect class="cls-1" height="2" width="20" x="7" y="11" />
        <rect class="cls-1" height="2" width="2" x="2" y="11" />
        <rect class="cls-1" height="2" width="23" x="7" y="17" />
        <rect class="cls-1" height="2" width="2" x="2" y="17" />
        <rect class="cls-1" height="2" width="23" x="7" y="5" />
        <rect class="cls-1" height="2" width="2" x="2" y="5" />
        <rect class="cls-1" height="2" width="18" x="7" y="23" />
        <rect class="cls-1" height="2" width="2" x="2" y="23" />
    </svg>
</template>

<style scoped>
.cls-1 {
    fill: #1282ff;
}
</style>
