import { type Promotion } from '../models/Posts';
import type {
    PaginatedCollection,
    Resource,
    ResourceCollection,
} from '../types/responses';
import ApiService from './base/ApiService';

export default class PromotionService extends ApiService {
    async index(page: number): Promise<PaginatedCollection<Promotion>> {
        const endpoint = '/api/promotions';
        const response = await this.fetch<PaginatedCollection<Promotion>>(
            endpoint,
            {
                query: { page },
            }
        );

        return response;
    }

    async get(slug: string): Promise<Resource<Promotion>> {
        const endpoint = `/api/promotions/${slug}`;
        const response = await this.fetch<Resource<Promotion>>(endpoint);

        return response;
    }

    async getSimilar(slug: string): Promise<ResourceCollection<Promotion>> {
        const endpoint = `/api/promotions/${slug}/similar`;
        const response =
            await this.fetch<ResourceCollection<Promotion>>(endpoint);

        return response;
    }
}
