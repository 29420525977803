import type { RouterConfig } from '@nuxt/schema';

// https://router.vuejs.org/api/interfaces/routeroptions.html
export default <RouterConfig>{
    scrollBehavior(to, from, savedPosition) {
        // Scroll to saved position when navigating back
        if (savedPosition) {
            return savedPosition;
        }

        // Scroll to hash when navigating to a page by #hash route
        if (to.hash) {
            return {
                el: to.hash,
                top: 90,
                behavior: 'smooth',
            };
        }

        // Scroll to top when navigating between pages
        return { top: 0, behavior: 'instant' };
    },
};
