import type { PhotoAlbum } from '../models/Photos';
import type { Resource, ResourceCollection } from '../types/responses';
import ApiService from './base/ApiService';

export default class PhotoService extends ApiService {
    async getGallery(): Promise<ResourceCollection<PhotoAlbum>> {
        const endpoint = '/api/gallery';
        const response =
            await this.fetch<ResourceCollection<PhotoAlbum>>(endpoint);

        return response;
    }

    async getAlbum(slug: string): Promise<Resource<PhotoAlbum>> {
        const endpoint = `/api/gallery/${slug}`;
        const response = await this.fetch<Resource<PhotoAlbum>>(endpoint);

        return response;
    }
}
