import type {
    Course,
    CourseCategory,
    CourseCertificate,
    CourseEntry,
    CoursesCategoryWithCounter,
} from '../models/Courses';
import type { Resource, ResourceCollection } from '../types/responses';
import ApiService from './base/ApiService';

export default class CourseService extends ApiService {
    async getCategories(): Promise<ResourceCollection<CourseCategory>> {
        const endpoint = '/api/courses/categories';
        const response =
            await this.fetch<ResourceCollection<CourseCategory>>(endpoint);

        return response;
    }

    async getCategoriesWithCounters(): Promise<
        ResourceCollection<CoursesCategoryWithCounter>
    > {
        const endpoint = '/api/courses/categories/counters';
        const response =
            await this.fetch<ResourceCollection<CoursesCategoryWithCounter>>(
                endpoint
            );

        return response;
    }

    async getCourses(): Promise<CourseEntry[]> {
        const endpoint = '/api/courses';
        const response = await this.fetch<CourseEntry[]>(endpoint);

        return response;
    }

    async getCourse(slug: string): Promise<Resource<Course>> {
        const endpoint = `/api/courses/${slug}`;
        const response = await this.fetch<Resource<Course>>(endpoint);

        return response;
    }

    async getSimilarCourses(slug: string): Promise<ResourceCollection<Course>> {
        const endpoint = `/api/courses/${slug}/similar`;
        const response = await this.fetch<ResourceCollection<Course>>(endpoint);

        return response;
    }

    async getCertificates(): Promise<ResourceCollection<CourseCertificate>> {
        const endpoint = '/api/courses/certificates';
        const response =
            await this.fetch<ResourceCollection<CourseCertificate>>(endpoint);

        return response;
    }

    async submitTestResults(
        payload: Record<string, any>
    ): Promise<ResourceCollection<Course>> {
        const endpoint = '/api/courses/test';
        const response = await this.fetch<ResourceCollection<Course>>(
            endpoint,
            { method: 'post', body: payload }
        );

        return response;
    }
}
