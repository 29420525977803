<script lang="ts" setup>
interface Events {
    (e: 'submit', value: Event): void;
    (e: 'reset', value: Event): void;
}

const emit = defineEmits<Events>();
</script>

<template>
    <form
        novalidate
        class="flex flex-col gap-2 text-black"
        @submit.prevent="emit('submit', $event)"
        @reset="emit('reset', $event)"
    >
        <slot />
    </form>
</template>

<style scoped></style>
