<script lang="ts" setup>
interface Props {
    size?: number;
}

const props = withDefaults(defineProps<Props>(), {
    size: 1,
});
</script>

<template>
    <svg
        :width="21 * props.size"
        :height="21 * props.size"
        viewBox="0 0 34 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M3.38359 34.125L0.0585938 30.8L13.3586 17.5L0.0585938 4.2L3.38359 0.875L16.6836 14.175L29.9836 0.875L33.3086 4.2L20.0086 17.5L33.3086 30.8L29.9836 34.125L16.6836 20.825L3.38359 34.125Z"
            fill="currentColor"
        />
    </svg>
</template>

<style scoped></style>
