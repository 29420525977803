<script lang="ts" setup>
const appConfig = useAppConfig();
</script>

<template>
    <NuxtLink to="/">
        <NuxtImg
            src="/images/logo.png"
            sizes="152px lg:119px xl:197px"
            :alt="appConfig.title"
            preload
        />
    </NuxtLink>
</template>

<style scoped></style>
