<script lang="ts" setup>
import { type Component } from 'vue';
import { type AutocompleteOptions } from '~/layers/uikit/types/html';

interface Props {
    modelValue?: string | undefined;
    name: string;
    autocomplete?: AutocompleteOptions;
    placeholder?: string;
    disabled?: boolean;
    readonly?: boolean;
    required?: boolean;
    type?: 'text' | 'password' | 'email' | 'tel' | 'number' | 'url';
    clearable?: boolean;
    icon?: Component;
}

interface Events {
    (e: 'update:modelValue', value?: string): void;
    (e: 'focus', value: FocusEvent): void;
    (e: 'blur', value: FocusEvent): void;
    (e: 'action'): void;
}

const props = withDefaults(defineProps<Props>(), {
    modelValue: undefined,
    type: 'text',
    placeholder: '',
    autocomplete: 'off',
    clearable: false,
    icon: undefined,
});

const emit = defineEmits<Events>();

const uniqueIdentifier = useId().replace('-', '_');

const field = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        if (value === '') {
            value = undefined;
        }

        emit('update:modelValue', value);
    },
});

const placeholderHint = computed(() => {
    if (!props.placeholder) {
        return undefined;
    }

    if (props.required) {
        return `${props.placeholder}*`;
    }

    return props.placeholder;
});

const isClearIconActive = computed(() => {
    return props.clearable && field.value !== undefined;
});

const inputClasses = computed(() => {
    let slotsToRender = 0;

    if (props.clearable) {
        slotsToRender++;
    }

    if (props.icon !== undefined) {
        slotsToRender++;
    }

    return {
        'pr-8': slotsToRender === 1,
        'pr-14': slotsToRender === 2,
    };
});

function clear() {
    field.value = undefined;
}

function onIconClick() {
    emit('action');
}

defineOptions({
    inheritAttrs: false,
});
</script>

<template>
    <div class="flex relative">
        <input
            :id="uniqueIdentifier"
            ref="reference"
            v-model="field"
            v-bind="$attrs"
            :type="props.type"
            :name="props.name"
            :placeholder="placeholderHint"
            :disabled="props.disabled"
            :readonly="props.readonly"
            :required="props.required"
            :autocomplete="props.autocomplete"
            :class="inputClasses"
            class="py-3 rounded-xl border-uk-gray focus:border-uk-orange focus:ring-0 transition-colors peer"
            @focus="emit('focus', $event)"
            @blur="emit('blur', $event)"
        />

        <div
            class="flex items-center gap-2 h-full absolute right-2.5 text-uk-gray peer-focus:text-uk-orange transition-colors"
        >
            <IconClose
                v-if="isClearIconActive"
                :size="0.6"
                class="cursor-pointer text-uk-gray"
                @click="clear"
            />

            <div class="cursor-pointer">
                <component
                    :is="props.icon"
                    v-if="props.icon"
                    @click="onIconClick"
                />
            </div>
        </div>
    </div>
</template>

<style scoped></style>
