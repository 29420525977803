<script lang="ts" setup>
interface Props {
    size?: number;
}

const props = withDefaults(defineProps<Props>(), {
    size: 1,
});
</script>

<template>
    <svg
        :width="21 * props.size"
        :height="21 * props.size"
        viewBox="0 0 51 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M48.2571 29.6553H42.8398C40.7901 29.6553 40.1726 27.9952 36.4985 24.3187C33.2875 21.2241 31.9314 20.8393 31.1191 20.8393C29.9957 20.8393 29.6894 21.1481 29.6894 22.6942V27.5677C29.6894 28.8858 29.2619 29.6577 25.8181 29.6577C22.4772 29.4332 19.2374 28.4183 16.3656 26.6964C13.4937 24.9746 11.072 22.5951 9.3 19.7539C5.09242 14.5171 2.16481 8.37074 0.75 1.80368C0.75 0.991425 1.05875 0.255176 2.60725 0.255176H8.02225C9.414 0.255176 9.91513 0.875051 10.4614 2.30718C13.0905 10.0449 17.5769 16.7757 19.3985 16.7757C20.0968 16.7757 20.4008 16.4669 20.4008 14.7261V6.75555C20.1704 3.11943 18.2395 2.81305 18.2395 1.4973C18.2639 1.15017 18.4227 0.826241 18.6821 0.594309C18.9416 0.362378 19.2812 0.240719 19.6289 0.255176H28.1409C29.3046 0.255176 29.6894 0.834675 29.6894 2.22643V12.9852C29.6894 14.1466 30.1881 14.5313 30.5396 14.5313C31.2379 14.5313 31.7746 14.1466 33.0548 12.8688C35.7987 9.52239 38.0405 5.79407 39.7095 1.8013C39.8803 1.32191 40.2033 0.911565 40.6291 0.632841C41.0549 0.354117 41.5602 0.222319 42.0679 0.25755H47.4852C49.1097 0.25755 49.4541 1.0698 49.1097 2.2288C47.1394 6.64254 44.7014 10.8322 41.8375 14.7261C41.2532 15.6167 41.0205 16.0798 41.8375 17.1248C42.3742 17.9371 44.2743 19.5236 45.5496 21.0317C47.4069 22.8842 48.9482 25.0264 50.1144 27.3729C50.5799 28.8834 49.8032 29.6553 48.2571 29.6553Z"
            fill="currentColor"
        />
    </svg>
</template>

<style scoped></style>
