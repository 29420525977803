<script lang="ts" setup>
interface Props {
    level: 1 | 2 | 3;
    heading?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
    level: 1,
    heading: true,
});

const headingNodes = {
    1: 'h1',
    2: 'h2',
    3: 'h3',
};

const headingClasses = computed(() => {
    return {
        'text-xl md:text-2xl lg:text-3xl xl:text-4xl': props.level === 1,
        'text-lg md:text-xl lg:text-2xl xl:text-3xl': props.level === 2,
        'text-base md:text-lg lg:text-xl xl:text-2xl': props.level === 3,
    };
});
</script>

<template>
    <component
        :is="props.heading ? headingNodes[props.level] : 'span'"
        :class="headingClasses"
        class="font-bold"
    >
        <slot />
    </component>
</template>

<style scoped></style>
