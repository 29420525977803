<script lang="ts" setup>
const cart = useCartStore();

const itemsCounter = computed(() => cart.state.items.length);
</script>

<template>
    <NuxtLink to="/cart" class="relative">
        <IconCart :size="1.8" class="text-uk-orange" />

        <ClientOnly>
            <small
                v-if="itemsCounter > 0"
                class="bg-uk-blue text-white rounded-full px-1 min-w-[33px] text-center font-bold absolute -bottom-0 -right-3.5"
            >
                {{ itemsCounter }}
            </small>
        </ClientOnly>
    </NuxtLink>
</template>

<style scoped></style>
