import type { SearchResult } from '../models/Search';
import ApiService from './base/ApiService';

export default class SearchService extends ApiService {
    async search(term: string | undefined): Promise<Array<SearchResult>> {
        if (!term) {
            return [];
        }

        const endpoint = '/api/search';
        const response = await this.fetch<Array<SearchResult>>(endpoint, {
            query: { q: term },
        });

        return response;
    }
}
